import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue';
import CorporationsView from '@/views/CorporationsView.vue';
import store from "@/store";
import AssetsView from "@/views/AssetsView.vue";
import ScannersView from "@/views/ScannersView.vue";


const routes = [
  {
    path: '/',
    name: "Home",
    component: HomeView,
  },
  {
    path: '/corporations',
    name: "Corporations",
    component: CorporationsView,
  },
  {
    path: '/assets',
    name: "Assets",
    component: AssetsView,
  },
  {
    path: '/scanners',
    name: "Scanners",
    component: ScannersView,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, _, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next();
      return;
    }
    next('/login');
  } else {
    next();
  }
});

export default router;
