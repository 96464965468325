<template>
  <h1>Corporations</h1>
  <p>This is a preview of <code>GET {{pathJoin([backend_url, '/corporations'])}}</code> list of json objects</p>
  <div id="corporations">
    <div>
      <table class="corporations-table" style="width: 100%; ">
        <tr>
          <th>
            Corporation
          </th>
        </tr>
        <template v-for="corporation in corporations" :key="corporation.name" >
          <tr>
            <td style="border: 1px solid darkgray; padding: 3px; text-align: center;" rowspan="2"><img width="64" height="64" :src="pathJoin([backend_url, corporation.logo])"></td>
            <td style="border: 1px solid darkgray; padding: 3px"><strong>{{corporation.name}}</strong> [<strong>{{corporation.symbol}}</strong>]</td>
          </tr>
          <tr>
            <td style="border: 1px solid darkgray; padding: 3px">{{corporation.description}}</td>
          </tr>
        </template>
      </table>
    </div>
  </div>
</template>


<script>

import { defineComponent } from 'vue';
import axios from 'axios';

export default defineComponent({
  name: 'CorporationsView',
    data () {
      return {
         corporations: [],
         backend_url: process.env.VUE_APP_FASTAPI_URL_PREFIX,
          pathJoin(parts, sep){
              const separator = sep || '/';
              parts = parts.map((part, index)=>{
                  if (index) {
                      part = part.replace(new RegExp('^' + separator), '');
                  }
                  if (index !== parts.length - 1) {
                      part = part.replace(new RegExp(separator + '$'), '');
                  }
                  return part;
              })
              return parts.join(separator);
           }
      }
    },
    mounted() {
      axios
      .get('/corporations')
      .then((response) => {
        this.corporations = response.data
      })
  }

});

</script>