<template>
  <h1>Scanners</h1>
  <p>
    This is a preview of Scanners list at
    <code>GET {{pathJoin([backend_url, '/assets'])}}?item_type=scanner</code>.
  </p>
  <div id="assets">
    <div>
      <table class="assets-table" style="width: 100%; ">
        <tr>
          <th>
            Scanner
          </th>
          <th>
            Class
          </th>
          <th>
            Scan time / Cooldown
          </th>
          <th>
            Base probability
          </th>
          <th>
            Depth
          </th>
          <th>
            Deposit Size Variance
          </th>
        </tr>
        <template v-for="asset in assets" :key="asset.name" >
          <tr v-if="asset.attributes.item_type === 'scanner'">
            <td style="border: 1px solid darkgray; padding: 3px"><strong>[<strong>{{asset.symbol}}</strong>] {{asset.name}}</strong><br/>{{asset.description}}</td>
            <td style="border: 1px solid darkgray; padding: 3px">{{asset.attributes.class}}</td>
            <td style="border: 1px solid darkgray; padding: 3px; text-align: right" >{{asset.scanner_attributes.scan_time}}<br/>{{asset.scanner_attributes.cooldown_time}}</td>
            <td style="border: 1px solid darkgray; padding: 3px; text-align: right" >{{asset.scanner_attributes.base_probability}}</td>
            <td style="border: 1px solid darkgray; padding: 3px; text-align: right" >{{asset.scanner_attributes.depth_multiplier}}</td>
            <td style="border: 1px solid darkgray; padding: 3px; text-align: right" >{{asset.scanner_attributes.yield_variance}}</td>
          </tr>
        </template>
      </table>
      <p>&nbsp;</p>
<h3>Scan Time</h3>
<p>Represents the duration required to complete one scanning cycle, measured in seconds. A shorter scan time means the scanner can complete its exploration faster, allowing for quicker resource discovery.</p>

<h3>Cooldown Time</h3>
<p>The mandatory waiting period before the scanner can be used again, measured in seconds. This simulates the scanner's need to recalibrate or rest between uses, adding a strategic element to resource exploration.</p>

<h3>Base Probability</h3>
<p>Indicates the fundamental chance (expressed as a percentage) of successfully discovering resources during a scan. Higher probabilities signify more advanced scanners capable of more accurate detections.</p>

<h3>Depth</h3>
<p>Reflects the scanner's ability to penetrate geological layers, with higher values indicating the capability to explore deeper and potentially uncover more valuable or rare resources. This multiplier affects the size of the deposits found, not the quantity of resources extracted per unit of time.</p>

<h3>Deposit Size Variance</h3>
<p>Describes the potential fluctuation in the quantity of resources discovered by the scanner, expressed as a percentage. A higher variance means the actual quantity of resources found can significantly differ from the average expectation, introducing an element of risk and variability into resource exploration.</p>
    </div>
  </div>
</template>


<script>

import { defineComponent } from 'vue';
import axios from 'axios';

export default defineComponent({
  name: 'assetsView',
    data () {
      return {
         assets: [],
         backend_url: process.env.VUE_APP_FASTAPI_URL_PREFIX,
          pathJoin(parts, sep){
              const separator = sep || '/';
              parts = parts.map((part, index)=>{
                  if (index) {
                      part = part.replace(new RegExp('^' + separator), '');
                  }
                  if (index !== parts.length - 1) {
                      part = part.replace(new RegExp(separator + '$'), '');
                  }
                  return part;
              })
              return parts.join(separator);
           }
      }
    },
    mounted() {
      axios
      .get('/assets')
      .then((response) => {
        this.assets = response.data
      })
  }

});

</script>