<template>
  <h1>Preview of APIs</h1>
  <section>
    <p>Here you can preview some of the APIs from <a :href="backend_url + 'docs'">{{backend_url}}</a>. The idea
    is to developers visual representation of json objects accessible through Meta API.</p>
  </section>
  <ul>
    <li><a href="/corporations">Corporations</a> - List of all NPC corporation brands that "developed" various
      in game assets.</li>
    <li><a href="/assets">All assets</a> - List of all in-game assets.</li>
    <li><a href="/scanners">Scanners</a> - List of scanning facilities you can use to find resource deposits.</li>
  </ul>
</template>
<script>

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'HomeView',
  computed : {
    isLoggedIn: function() {
      return this.$store.getters.isAuthenticated;
    }
  },
  methods: {
   log(){
    console.log(process.env);
   }
  },
  data() {
    return {
      backend_url: process.env.VUE_APP_FASTAPI_URL_PREFIX
    };
  }
});



</script>
