import { createApp } from "vue";
import axios from 'axios';
import App from './App.vue';
import router from './router';
import store from './store';
import Vue3EasyDataTable from 'vue3-easy-data-table';
const app = createApp(App);

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'vue3-easy-data-table/dist/style.css';


axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_FASTAPI_URL_PREFIX;  // the FastAPI backend

axios.interceptors.response.use(undefined, function (error) {
  if (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      store.dispatch('logOut');
      return router.push('/login')
    }
  }
});

app.use(router);
app.use(store);
app.component('EasyDataTable', Vue3EasyDataTable);
app.mount("#app");
