<template>
  <h1>Assets</h1>
  <p>This is a preview of <code>GET {{pathJoin([backend_url, '/assets'])}}</code> list of json objects</p>
  <div id="assets">
    <div>
      <table class="assets-table" style="width: 100%; ">
        <tr>
          <th>
            Symbol
          </th>
          <th>
            Name
          </th>
          <th>
            Item Type
          </th>
          <th>
            Class
          </th>
          <th>
            Description
          </th>
        </tr>
        <template v-for="asset in assets" :key="asset.name" >
          <tr>
            <td style="border: 1px solid darkgray; padding: 3px" width="100px"><h5>[{{asset.symbol}}]</h5></td>
            <td style="border: 1px solid darkgray; padding: 3px"><h5>{{asset.name}}</h5></td>
            <td style="border: 1px solid darkgray; padding: 3px">{{asset.attributes.item_type}}</td>
            <td style="border: 1px solid darkgray; padding: 3px">{{asset.attributes.class}}</td>
            <td style="border: 1px solid darkgray; padding: 3px" >{{asset.description}}</td>
          </tr>
        </template>
      </table>
    </div>
  </div>
</template>


<script>

import { defineComponent } from 'vue';
import axios from 'axios';

export default defineComponent({
  name: 'assetsView',
    data () {
      return {
         assets: [],
         backend_url: process.env.VUE_APP_FASTAPI_URL_PREFIX,
          pathJoin(parts, sep){
              const separator = sep || '/';
              parts = parts.map((part, index)=>{
                  if (index) {
                      part = part.replace(new RegExp('^' + separator), '');
                  }
                  if (index !== parts.length - 1) {
                      part = part.replace(new RegExp(separator + '$'), '');
                  }
                  return part;
              })
              return parts.join(separator);
           }
      }
    },
    mounted() {
      axios
      .get('/assets')
      .then((response) => {
        this.assets = response.data
      })
  }

});

</script>